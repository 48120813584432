import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import { userReducer } from 'redux/features/userSlice.redux';
import { referrerReducer } from 'redux/features/referrerSlice.redux';
import { planReducer } from 'redux/features/planSlice.redux';
import { miscReducer } from '../features/miscSlice.redux';

const logger = createLogger();

export const store = configureStore({
    reducer: {
        user: userReducer,
        referrer: referrerReducer,
        plan: planReducer,
        misc: miscReducer,
    },
    middleware: (getDefaultMiddleware) =>
        process.env.REACT_APP_ENV === 'production' ? getDefaultMiddleware() : getDefaultMiddleware().concat(logger),
});

export type StoreType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
