import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { resetAll } from './resetSlice.redux';

import { PlanInterface } from 'interfaces/store/plan.interface';
import { DATA_CONSTANTS } from 'constants/data.constant';

const initialState: PlanInterface = {
    countryCode: '',
    currencyCode: '',
    activePlanIndex: 0,
    activeCouponCode: '',
    isCouponCodeHidden: false,
    discountValue: '',
    planOptions: [
        {
            planCode: DATA_CONSTANTS.PLAN_CODES.ANNUAL,
            monthCount: 12,
            displayPrice: 0,
            realPrice: 0,
            discountedPrice: 0,
        },
    ],
};

const planSlice = createSlice({
    name: 'Plan',
    initialState,
    reducers: {
        updateDetails: (state, action: PayloadAction<Partial<PlanInterface>>) => {
            return { ...state, ...action.payload };
        },
        setPlan: (state, action: PayloadAction<Partial<PlanInterface>>) => ({ ...state, ...action.payload }),
        updatePlanOptions: (state, action: PayloadAction<{ discountedPrice: number }>) => {
            if (state.planOptions[state.activePlanIndex]) {
                state.planOptions[state.activePlanIndex].discountedPrice = action.payload.discountedPrice;
            }
        },
        setCoupon: (
            state,
            action: PayloadAction<{
                activeCouponCode: string;
                discountValue?: string;
                isCouponCodeHidden?: boolean;
            }>,
        ) => {
            return { ...state, ...action.payload };
        },
        setPresetCoupon: (state, action: PayloadAction<string>) => {
            state.presetCouponCode = action.payload;
        },
        setAffiliateEmail: (state, action: PayloadAction<string>) => {
            state.affiliateEmail = action.payload;
        },
    },
    extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
});

export const planReducer = planSlice.reducer;
export const planActions = planSlice.actions;
