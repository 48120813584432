import { useQuery } from 'helpers/customHooks/useQuery.hook';

function App() {
    const query = useQuery();
    const referralCode = query.get('s') || '';
    const affiliateUrlCode = query.get('a') || '';

    const params = {
        s: referralCode,
        a: affiliateUrlCode,
    };

    // Filter out parameters with empty string values
    const filteredParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => value !== ''));

    // Create the query string using URLSearchParams
    const queryString = new URLSearchParams(filteredParams).toString();
    let url = `${process.env.REACT_APP_WEBAPP}/cp/checkout`;
    if (queryString) {
        url += `?${queryString}`;
    }

    // console.log(url);
    window.location.href = url;

    return null;
}

export default App;
