import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { resetAll } from './resetSlice.redux';

import { UserInterface } from 'interfaces/store/user.interface';

const initialState: UserInterface = {
    email: '',
    firstName: '',
    lastName: '',
    planType: '',
    userId: '',
};

const userSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<Partial<UserInterface>>) => ({ ...state, ...action.payload }),
    },
    extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;
