export interface MiscInterface {
    displayLanguage: string;
    onProEmail: string;
    errorTitle: string;
    errorDetails: string;
    showPage: PageType;
}

export enum PageType {
    None,
    Error,
    OnPro,
}
