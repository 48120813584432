import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { resetAll } from './resetSlice.redux';

import { ReferrerInterface } from 'interfaces/store/referrer.interface';

const initialState: ReferrerInterface = {
    email: '',
    firstName: '',
    lastName: '',
};

const referrerSlice = createSlice({
    name: 'Referrer',
    initialState,
    reducers: {
        setReferrer: (state, action: PayloadAction<Partial<ReferrerInterface>>) => ({ ...state, ...action.payload }),
    },
    extraReducers: (builder) => builder.addCase(resetAll, () => initialState),
});

export const referrerReducer = referrerSlice.reducer;
export const referrerActions = referrerSlice.actions;
