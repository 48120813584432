import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import reportWebVitals from './reportWebVitals';

import './index.scss';

import { store } from 'redux/app/store.redux';
import App from 'App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

i18next
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: false,
        detection: {
            caches: ['localStorage'],
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        },
        react: {
            useSuspense: false,
        },
    });

Sentry.init({
    release: '1.1.0',
    dsn: 'https://af3fc89cc4be4c8ba5410d038b27947f@o1095904.ingest.sentry.io/4504368801316864',
    integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })],
    tracesSampleRate: 1.0, //lower the value in production
    environment: window.location.hostname === 'localhost' ? 'local' : process.env.REACT_APP_ENV,
    normalizeDepth: 10,
    beforeSend: (event) => {
        if (window.location.hostname === 'localhost') {
            return null;
        }
        return event;
    },
});

root.render(
    <StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>
    </StrictMode>,
);

reportWebVitals();
