import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { resetAll } from './resetSlice.redux';

import { MiscInterface, PageType } from '../../interfaces/store/misc.interfaces';

const initialState: MiscInterface = {
    displayLanguage: '',
    onProEmail: '',
    errorTitle: '',
    errorDetails: '',
    showPage: PageType.None,
};

const miscSlice = createSlice({
    name: 'Misc',
    initialState,
    reducers: {
        updateDetails: (state, action: PayloadAction<Partial<MiscInterface>>) => {
            return { ...state, ...action.payload };
        },
        setLanguage: (state, action: PayloadAction<string>) => {
            state.displayLanguage = action.payload;
        },
        setOnPro: (state, action: PayloadAction<string>) => {
            state.onProEmail = action.payload;
        },
        setError: (state, action: PayloadAction<{ errorTitle: string; errorDetails: string }>) => {
            return { ...state, ...action.payload };
        },
        setPage: (state, action: PayloadAction<PageType>) => {
            state.showPage = action.payload;
        },
    },
    extraReducers: (builder) =>
        builder.addCase(resetAll, (state) => {
            state.onProEmail = '';
            state.errorTitle = '';
            state.errorDetails = '';
            state.showPage = PageType.None;
        }),
});

export const miscReducer = miscSlice.reducer;
export const miscActions = miscSlice.actions;
