export const DATA_CONSTANTS = {
    PLAN_CODES: {
        ANNUAL: 'Annual',
    },

    REFERRAL: {
        REFER_FREE_COUNT: 1,
    },

    REGEX: {
        EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12})+$/,
    },

    ID_SHOPEE_URL: '',
};
